/*
    Styles to make 'chosen' selects look more like Bootstrap 3

    Based on https://gist.github.com/koenpunt/6424137, revision 2, Sep 3, 2013
*/

@media screen and (max-width: 767px) {
    .chosen-container {
        margin-bottom: 10px;
    }
}

.chosen-container.chosen-container-single .chosen-single {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    color: #555;
    vertical-align: middle;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    background-image: none !important;
}

.chosen-container.chosen-container-single.chosen-disabled .chosen-single {
    background-color: #eee;
    cursor: not-allowed;
}

.chosen-container.chosen-container-single .chosen-single div {
    top: 4px;
    color: #000;
}

.chosen-container .chosen-drop {
    background-color: #FFF;
    border: 1px solid #CCC;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
    margin: 2px 0 0;
    width: auto;
    min-width: 100%;
}

.chosen-container .chosen-search input[type=text] {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    color: #555;
    vertical-align: middle;
    background-color: #FFF;
    border: 1px solid #CCC;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    background-image: none !important;
}

.chosen-container .chosen-results {
    margin: 2px 0 0;
    padding: 5px 0;
    font-size: 14px;
    list-style: none;
    background-color: #fff;
    margin-bottom: 5px;
}

.chosen-container .chosen-results li,
.chosen-container .chosen-results li.active-result {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.428571429;
    white-space: nowrap;
    background-image: none !important;
}

.chosen-container .chosen-results li:hover,
.chosen-container .chosen-results li.active-result:hover,
.chosen-container .chosen-results li.highlighted {
    color: #FFF;
    text-decoration: none;
    background-color: #428BCA;
    background-image: none !important;
}

.chosen-container-multi .chosen-choices {
    display: block;
    width: 100%;
    min-height: 34px;
    padding: 0 2px 2px 2px;
    font-size: 14px;
    line-height: 1.428571429;
    color: #555;
    vertical-align: middle;
    background-color: #FFF;
    border: 1px solid #CCC;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    background-image: none !important;
}

.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
    height: auto;
    padding: 6px 0 0 8px;
}

.chosen-container-multi .chosen-choices li.search-choice {
    background-image: none !important;
    padding: 3px 24px 3px 5px;
    margin: 2px 2px 0 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.428571429;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #eee;
    border-color: #CCC;
}

.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
    top: 0;
    right: 0;
    padding: 13px;
    background: none !important;
    color: #888;
}

.chosen-container-multi .chosen-choices li.search-choice .search-choice-close:before {
    position: absolute;
    top: 6px;
    right: 7px;
    font-family: 'Glyphicons Halflings';
    content: "\e014";
    font-size: 11px;
    text-decoration: none;
}
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close:hover {
    color: black;
}

.chosen-container-multi.chosen-container-active .chosen-choices,
.chosen-container.chosen-container-single.chosen-container-active .chosen-single,
.chosen-container .chosen-search input[type=text]:focus {
    border-color: #66AFE9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),0 0 8px rgba(102, 175, 233, 0.6);
}

.chosen-container-multi.chosen-container-active .chosen-choices {
    padding: 0 2px 2px 2px;
}

.chosen-container-multi .chosen-results li.result-selected {
    display: list-item;
    color: #ccc;
    cursor: default;
    background-color: white;
}

.chosen-container.chosen-disabled {
    opacity: 1 !important;
}

.chosen-container.chosen-disabled .chosen-choices,
.chosen-container-multi.chosen-disabled .chosen-choices li.search-choice,
.chosen-container-multi.chosen-disabled .chosen-choices li.search-choice .search-choice-close:hover,
.chosen-container.chosen-disabled .chosen-choices .search-field input {
    background-color: #eee;
    cursor: not-allowed;
}

.chosen-container-multi.chosen-disabled .chosen-choices li.search-choice .search-choice-close:hover {
    background-position: -42px 1px;
}

.input-group .chosen-container .chosen-choices:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
