/* TODO: styles are a big mess. somethings are located here that should be part of main.css and the other way around */

/* Tekis Components v0.1 */

/* General */

.col-md-0, .col-sm-0 {
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    .col-sm-0 {
        width: 100%;
        float: left;
        margin-top: 5px;
    }
}
@media screen and (min-width: 992px) {
    .col-md-0 {
        width: 100%;
        float: left;
        margin-top: 5px;
    }
}

.tekis-components .btn-gray {
    background-color: #f0f0f0;
    border-color: #ccc;
}

.tekis-components .btn-gray:hover {
    background-color: #e6e6e6;
}

.tekis-components .btn-tekis {
    background-color: #3eb1c8;
    border-color: #3eb1c8;
}

.tekis-components .btn-tekis:hover {
    background-color: #8bd0de;
    border-color: #8bd0de;
}

.tekis-components .topmenu {
    position: fixed;
    top: 50px;
    width: 100%;
    height: 50px;
    z-index: 3;
    background-color: #e0e0e0;
    white-space: nowrap;
    border-bottom: 1px solid #d0d0d0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    padding-top: 1px;
}

.tekis-components .content {
    position: relative;
    top: 100px;
    padding-top: 10px;
    padding-bottom: 0;
    z-index: 2;
}

.tekis-components .content.noactions {
    top: 50px;
}

@media screen and (min-width: 1055px) {
    .tekis-components .topmenu .container-fluid,
    .tekis-components .content {
        padding-left: 0;
        padding-right: 0;
    }
}

.tekis-components .topmenu .actions {
    display: inline-block;
    margin: 7px 0;
}
.tekis-components .topmenu .actions:not(:first-child) {
    padding-left: 7px;
}

.tekis-components .topmenu .actions.separator {
    border-right: 1px solid #c0c0c0;
    padding-right: 7px;
}

@media screen and (max-width: 599px) {
    .tekis-components .topmenu .actions button {
        padding: 6px 4px;
    }
    .tekis-components .topmenu .actions.separator {
        border-right: none;
        padding-right: 2px;
    }
}

.tekis-components .has-feedback .form-control {
    padding-right: 34px;
}

.tekis-components tekis-datetime .has-feedback .form-control-feedback {
    right: 0;
}

.tekis-components .breakword {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
}

/* Validation */

.tekis-components .validationMessage {
    color: #c8102e;
}

/* Panels */

.tekis-components .panel {
    margin: 0 0 10px 0;
    box-shadow: none;
    border: none;
}

.tekis-components .panel:not(.expanded) > .panel-heading {
    border: none;
}

.tekis-components .panel-default > .panel-heading {
    background-color: #3eb1c8;
    color: white;
    border: none;
}
.tekis-components .panel-success > .panel-heading {
    background-color: #297686;
}

.tekis-components .panel .panel-heading.clickable {
    transition: background-color 0.3s ease;
}

.tekis-components .panel.panel-default .panel-heading.clickable:hover {
    background-color: #8bd0de;
}

.tekis-components .panel .panel .panel-body,
.tekis-components .panel .panel .panel .panel .panel-body {
    background-color: #f0f0f0;
}
.tekis-components .panel .panel .panel .panel-body {
    background-color: #fff;
}

.tekis-components .panel .panel-heading > h4 {
    font-size: 15px;
    font-weight: bold;
}

.tekis-components .panel .panel-heading.collapsed > h4 > .glyphicon:before {
    content: "\e080";
}

.tekis-components .panel .panel-heading:not(.collapsed) > h4 > .glyphicon:before {
    content: "\e114";
}

/* Textarea */

.tekis-components pre {
    border: inherit;
    background-color: inherit;
    font-family: inherit;
    font-size: inherit;
}

.tekis-components textarea {
    resize: none;
}

.tekis-components textarea[readonly] {
    background-color: transparent;
    padding: 0;
    border: none;
    box-shadow: none;
    cursor: text;
}
.tekis-components textarea[readonly].hastitle {
    padding: 7px 0;
}

/* Header */

.tekis-components h5 {
    border-bottom: 1px solid #ddd;
    color: #3eb1c8;
    font-size: 120%;
    margin: 15px 0;
}

/* Hr */

.tekis-components hr {
    margin: 15px 0;
    border-color: #ddd;
}

/* Checkboxes / Radios */

.tekis-components .checkbox,
.tekis-components .checkbox-inline,
.tekis-components .radio,
.tekis-components .radio-inline {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    /*-o-user-select: none;*/
    user-select: none;
    padding-bottom: 7px;
}

/* Selects */

.tekis-components .chosen-container-multi .chosen-choices li.search-choice {
    /*width: 100%;*/
    text-align: left;
    padding: 3px 24px 3px 7px;
}

.tekis-components .chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
    padding: 15px;
    top: -2px;
}

.tekis-components .chosen-container-multi .chosen-choices li.search-choice .search-choice-close:before {
    top: 8px;
    right: 7px;
}

/* Tables */

.tekis-components .table {
    margin-bottom: 0;
}
.tekis-components .table.withmargin {
    margin-bottom: 20px;
}

.tekis-components .table > thead > tr > th {
    background-color: #9fd8e4;
    border: none;
}
.tekis-components .table .table > thead > tr > th {
    background-color: #d8eff4;
}

.tekis-components .table > thead.bg-white > tr > th {
    background-color: #fff;
    border-bottom: 1px solid #ddd;
}

.tekis-components .table > tbody > tr {
    border-top: 1px solid #ddd;
    background-color: #fff;
}
.tekis-components .table > tbody > tr:first-child {
    border-top: none;
}
.tekis-components .table > tbody > tr > td {
    border: none;
}

.tekis-components .table.noborder > tbody > tr {
    border: none;
}
.tekis-components .table > tbody > tr.noborder {
    border: none;
}
.tekis-components .table > tbody > tr.bordertop {
    border-top: 1px solid #ddd;
}

.tekis-components .table > tbody > tr.active > td {
    background-color: #e0e0e0;
}

.tekis-components .table > tbody > tr > td.nowrap {
    white-space: nowrap;
}

.tekis-components .table > tfoot > tr > td {
    padding: 7px 0 0 0;
    border: none;
}
.tekis-components .table > tfoot > tr:first-child {
    border-top: none;
}

.tekis-components .table > tbody > tr > td.removeRowButton {
    padding-top: 7px;
    width: 51px;
}

.tekis-components .table > tbody > tr > td.movableRowButton {
    padding-top: 7px;
    width: 101px;
}

.tekis-components .table > tbody > tr > td.removeRowButton.movableRowButton {
    width: 151px;
}

.tekis-components .table > thead > tr > th.sort {
    padding-right: 24px;
}
.tekis-components .table > thead > tr > th.sort:after {
    display: block;
    position: relative;
    text-align: right;
    top: -17px;
    right: -24px;
    padding-right: 8px;
    height: 0;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    content: "";
    opacity: 0.33;
    transition: opacity 0.3s ease;
}
.tekis-components .table > thead > tr > th.sort.sortAsc:after {
    content: "\e155";
    opacity: 0.66;
}
.tekis-components .table > thead > tr > th.sort.sortDesc:after {
    content: "\e156";
    opacity: 0.66;
}
.tekis-components .table > thead > tr > th.sort:hover:after {
    opacity: 1;
}

.tekis-components tekis-textbox:last-child div.form-group,
.tekis-components tekis-textarea:last-child div.form-group,
/*.tekis-components tekis-checkbox:last-child div.form-group,
.tekis-components tekis-radio:last-child div.form-group,*/
.tekis-components tekis-yesno:last-child div.form-group,
.tekis-components tekis-multibool:last-child div.form-group,
.tekis-components tekis-select:last-child div.form-group,
.tekis-components tekis-table:last-child div.form-group,
.tekis-components tekis-datetime:last-child div.form-group {
   margin-bottom: 0;
}
